<template>
  <v-container>
    <IPNDDetails
      :ipnd_id="parseInt(this.$route.params.ipndId)"
    ></IPNDDetails>
  </v-container>
</template>
<script>

import partnerDataMixin from '../../mixins/partnerDataMixin';
import IPNDDetails from "../../components/pieces/IPND/IPNDDetailsComponent.vue";
export default {
    name: 'PartnerIPNDDetails',
    mixins: [partnerDataMixin],
    components: {
      IPNDDetails,
    },
};
</script>
